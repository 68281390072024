import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MarkerF, PolylineF, InfoWindowF } from "@react-google-maps/api";
import pickupMarker from "images/pickup.png";
import dropoffMarket from "images/dropoff.png";
import driverMarket from "images/marker.png";
import { ToastError } from "utils/toasts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment-timezone";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PhoneIcon, ShareIcon } from "@heroicons/react/16/solid";
import Moment from "react-moment";
import { DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
import LocationTracker from "../../components/LocationTracker";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "components/dropdown";
import { Button } from "components/button";
import { MapView } from "components/MapView";
import { getTimeAndDistance } from "utils/common-utils";

export default function TripTracking() {
  const navigate = useNavigate();
  const [directions, setDirections] = React.useState(null);
  const [tripDetails, setTripDetails] = useState(null);
  let [expectedTimeDistance, setExpectedTimeDistance] = useState(null);
  let [routePath, setRoutePath] = useState([]);
  let [map, setMap] = useState();
  let params = useParams();
  let id = params.tripId;

  useEffect(() => {
    if (!id) return;
    getDataFromApi();
  }, [id]);

  useEffect(() => {
    if (
      tripDetails &&
      !(tripDetails.status === "cancelled" || tripDetails.status === "finished")
    ) {
      const intervalId = setInterval(() => {
        getDataFromApi();
      }, 5000);
      return () => {
        if (intervalId) clearInterval(intervalId);
      };
    }
  }, [tripDetails]);

  const onLoad = (myMap) => {
    map = myMap;
    setMap(myMap);
    if (tripDetails) setTripsBounds(tripDetails);
  };

  function getDataFromApi() {
    fetch(`${process.env.REACT_APP_BASEURL}assigns/tracking/${id}`)
      .then((response) => {
        if (!response.ok) {
          navigate("/expired");
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTripDetails(data);
        if (data.driver && data.driver.latitude && data.driver.longitude) {
          let distanceAndTime = null;
          if (data.jobStatus === "onway") {
            distanceAndTime = getTimeAndDistance(
              data.driver.latitude,
              data.driver.longitude,
              data.jobOriginLatitude,
              data.jobOriginLongitude,
              50
            );
            distanceAndTime.timeInMinutes = moment()
              .tz(data.company.timeZone)
              .add(distanceAndTime.timeInMinutes, "minutes");
          } else if (data.jobStatus === "started") {
            distanceAndTime = getTimeAndDistance(
              data.driver.latitude,
              data.driver.longitude,
              data.jobDestinationLatitude,
              data.jobDestinationLongitude,
              50
            );
            distanceAndTime.timeInMinutes = moment()
              .tz(data.company.timeZone)
              .add(distanceAndTime.timeInMinutes, "minutes");
          }
          expectedTimeDistance = distanceAndTime;
          setExpectedTimeDistance(expectedTimeDistance);
        }
      })
      .catch((error) => {
        navigate("/expired");
        console.error("There was a problem fetching the data:", error);
        ToastError(error.message);
      });
  }

  function setTripsBounds(data) {
    const pointA = {
      lat: data.jobOriginLatitude,
      lng: data.jobOriginLongitude,
    };
    const pointB = {
      lat: data.jobDestinationLatitude,
      lng: data.jobDestinationLongitude,
    };

    routePath = [pointA, pointB];
    setRoutePath(routePath);
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(pointA);
    bounds.extend(pointB);
    // driver
    if (data.driver) {
      bounds.extend({
        lat: data.driver.latitude,
        lng: data.driver.longitude,
      });
    }
    map.fitBounds(bounds);

    // draw Route
    const request = {
      origin: pointA,
      destination: pointB,
      travelMode: "DRIVING",
    };

    drawDirection(request);
  }

  const handleLocationUpdate = (location) => {
    if (tripDetails) {
      if (location) {
        console.log("User location:", location.latitude, location.longitude);

        // const distanceAndTime = getTimeAndDistance(
        //   tripDetails.jobOriginLatitude,
        //   tripDetails.jobOriginLongitude,
        //   location.latitude,
        //   location.longitude,
        //   50
        // );
        // 500 meter
        // if (distanceAndTime && (distanceAndTime.distance <= 0.310686)) {
        memberLiveLocation(location);
        // }
      } else {
        console.log("Failed to retrieve user location.");
      }
    }
  };

  const memberLiveLocation = (location) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        member: tripDetails.priorityClient,
        latitude: location.latitude,
        longitude: location.longitude,
        jobId: tripDetails.job,
        company: tripDetails.company,
        driverId: tripDetails.driver._id,
      }),
    };

    fetch(
      `${process.env.REACT_APP_BASEURL}assigns/tracking/${id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          // navigate("/expired");
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {});
  };

  const drawDirection = (request) => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        // Set the directions when the request is successful
        console.log(result);
        setDirections(result);
      } else {
        // Handle error
        console.error("Directions request failed due to " + status);
      }
    });
  };

  function confirmationTripAlert(data) {
    Swal.fire({
      title: "Alert",
      icon: "info",
      text: "Are you sure you want to cancel this trip?",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: `No`,
      focusConfirm: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmationTripReq(data);
      }
    });
  }

  function confirmationTripReq(params) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: params._id,
        company: params.company,
      }),
    };
    fetch(
      `${process.env.REACT_APP_BASEURL}assigns/markcompleted`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // if (data && data.status === "cancelled" ) {
        //   // this.props.history.push("/expire");
        //   navigate("/expired");
        // }

        Swal.fire({
          title: "Alert",
          icon: "success",
          text: "Trip updated successfully",
        });
      })
      .catch((error) => {
        navigate("/expired");
        throw error;
      });
  }

  return (
    <>
      {tripDetails ? (
        <>
          <section className="h-dvh relative">
            <div className="relative p-4 max-w-xl mx-auto space-y-8">
              <div className="rounded-md p-2 ring-1 ring-gray-200 flex justify-between items-center">
                <div>
                  {tripDetails.company &&
                    tripDetails.company.profileImageURL && (
                      <img
                        src={tripDetails.company.profileImageURL}
                        alt={tripDetails.company.displayName}
                        className="p-1 h-10 max-w-32 rounded-md border object-scale-down"
                      />
                    )}
                </div>
                <div className="text-sm font-bold text-gray-900">
                  Trip Tracking
                </div>
                <div>
                  {(tripDetails?.jobStatus === "onway" ||
                    tripDetails?.jobStatus === "arrived") && (
                    <Dropdown>
                      <DropdownButton plain aria-label="More options">
                        <EllipsisVerticalIcon />
                      </DropdownButton>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => confirmationTripAlert(tripDetails)}
                        >
                          Cancel Trip
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>
              </div>
              {expectedTimeDistance && (
                <div className="bg-[#293562] rounded-2xl overflow-hidden space-y-4 shadow-lg">
                  <div className="flex flex-col">
                    <div className="bg-white/20 text-white text-center p-2 text-sm font-medium">
                      Estimated Time of Arrival
                    </div>
                    <div className="flex items-center justify-between leading-none px-6 py-4">
                      <dt className="capitalize text-base font-black text-yellow-400 leading-tight">
                        {expectedTimeDistance.timeInMinutes.fromNow()},{" "}
                        {expectedTimeDistance.distance.toFixed(2)} Miles
                      </dt>
                      <dd className="text-sm font-bold text-yellow-400 leading-tight rounded-full px-2 py-1 ring-1 ring-yellow-400 bg-white/10">
                        {expectedTimeDistance.timeInMinutes.format("HH:mm a")}
                      </dd>
                    </div>
                  </div>
                </div>
              )}
              <div className="bg-white rounded-2xl border aspect-[2/1] shadow-lg overflow-hidden">
                <MapView onLoad={onLoad}>
                  {
                    <MarkerF
                      label={{
                        color: "white",
                        fontSize: "11px",
                        fontWeight: "bold",
                        text: "P",
                      }}
                      icon={{
                        url: pickupMarker,
                        scaledSize: {
                          width: 38,
                          height: 38,
                        },
                        labelOrigin: { x: 19, y: 14 },
                      }}
                      position={{
                        lat: tripDetails.jobOriginLatitude,
                        lng: tripDetails.jobOriginLongitude,
                      }}
                    >
                      {/* <InfoWindowF
                      position={{
                        lat: tripDetails.jobOriginLatitude,
                        lng: tripDetails.jobOriginLongitude,
                      }}
                    >
                      <div> {tripDetails.jobOriginAddress}</div>
                    </InfoWindowF> */}
                    </MarkerF>
                  }

                  {
                    <MarkerF
                      label={{
                        color: "white",
                        fontSize: "11px",
                        fontWeight: "bold",
                        text: "D",
                      }}
                      icon={{
                        url: dropoffMarket,
                        scaledSize: {
                          width: 38,
                          height: 38,
                        },
                        labelOrigin: { x: 19, y: 14 },
                      }}
                      position={{
                        lat: tripDetails.jobDestinationLatitude,
                        lng: tripDetails.jobDestinationLongitude,
                      }}
                    >
                      {/* <InfoWindowF
                      position={{
                        lat: tripDetails.jobDestinationLatitude,
                        lng: tripDetails.jobDestinationLongitude,
                      }}
                    >
                      <div> {tripDetails.jobDestinationAddress}</div>
                    </InfoWindowF> */}
                    </MarkerF>
                  }

                  {!(
                    tripDetails.status === "cancelled" ||
                    tripDetails.status === "finished"
                  ) &&
                    tripDetails.driver && (
                      <MarkerF
                        // label={{
                        //   color: 'white', fontSize: '11px',
                        //   fontWeight: 'bold', text: 'D'
                        // }}
                        icon={{
                          url: driverMarket,
                          scaledSize: {
                            width: 38,
                            height: 38,
                          },
                          labelOrigin: { x: 19, y: 14 },
                        }}
                        position={{
                          lat: tripDetails.driver.latitude,
                          lng: tripDetails.driver.longitude,
                        }}
                      ></MarkerF>
                    )}

                  {/* {routePath && routePath.length && (
                  <PolylineF
                    path={routePath}
                    options={{
                      strokeColor: "#3D5F3D",
                      strokeOpacity: 1,
                      strokeWeight: 4,
                    }}
                  />
                )} */}

                  {directions && (
                    <DirectionsRenderer
                      directions={directions}
                      options={{
                        suppressMarkers: true,
                        polylineOptions: {
                          strokeColor: "#293562",
                          strokeWeight: 5,
                        },
                      }}
                    />
                  )}
                </MapView>
              </div>
              <div className="bg-[#293562] rounded-2xl relative shadow-lg">
                <div className="absolute -top-3  inset-x-0 flex justify-center">
                  <div
                    className={`py-1 px-2 rounded-lg text-xs font-bold ring-2 ring-[#293562] uppercase ${
                      tripDetails.status === "cancelled"
                        ? "bg-red-500 text-white"
                        : "bg-green-100 text-gray-900"
                    }`}
                  >
                    {tripDetails?.jobStatus === "started"
                      ? "Member Onboard"
                      : tripDetails?.jobStatus}
                  </div>
                </div>
                <div className="pt-8">
                  {tripDetails.driver && (
                    <div className="flex items-center justify-between px-4 pb-5">
                      <div className="flex items-center gap-2 flex-1">
                        <div className="size-10 ring-1 ring-white rounded-full overflow-hidden">
                          <img
                            src={
                              tripDetails.driver &&
                              tripDetails.driver.profileImageURL
                            }
                            className="h-full w-full object-cover"
                            alt=""
                          />
                        </div>
                        <div className="flex flex-wrap flex-1 justify-between">
                          <div className="flex flex-col">
                            <div className="text-sm font-semibold text-white">
                              {tripDetails.driver &&
                                tripDetails.driver.displayName.split(" ")[0]}
                            </div>
                            <div className="text-xs text-gray-100">
                              Driver Name
                            </div>
                          </div>
                          <div className="flex flex-col text-right">
                            <div className="text-base font-semibold text-white">
                              {tripDetails && tripDetails.driver.setiDecall}
                            </div>
                            <div className="text-xs text-gray-100">
                              Vehicle No.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="rounded-2xl bg-white p-4 space-y-6">
                    {tripDetails.driver && (
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                          <div className="text-base font-semibold text-gray-900 leading-5">
                            {tripDetails && tripDetails.jobCarType}
                          </div>
                          <div className="text-sm text-gray-500 leading-5">
                            Service
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-base font-semibold text-gray-900 leading-5">
                            {tripDetails.driver &&
                              tripDetails.driver.driverCarColor}
                          </div>
                          <div className="text-sm text-gray-500 leading-5">
                            Color
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-base font-semibold text-gray-900 leading-5">
                            {tripDetails.driver &&
                              tripDetails.driver.driverCarModel}
                          </div>
                          <div className="text-sm text-gray-500 leading-5">
                            Make & Model
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-base font-semibold text-gray-900 leading-5">
                            {tripDetails.driver &&
                              tripDetails.driver.driverCarNumber}
                          </div>
                          <div className="text-sm text-gray-500 leading-5">
                            Plate No.
                          </div>
                        </div>
                      </div>
                    )}
                    <ul role="list" className="space-y-6">
                      <li className="relative flex gap-x-4">
                        <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                          <div className="w-px  border-r border-gray-200 border-dashed"></div>
                        </div>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-3 text-green-400"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="currentColor"
                              d="M13.756 10.164c1.665-.962 1.665-3.366 0-4.328L5.251.919C3.584-.045 1.5 1.158 1.5 3.083v9.834c0 1.925 2.084 3.128 3.751 2.164z"
                            />
                          </svg>
                        </div>
                        <div className="flex-auto space-y-1">
                          <p className="pt-1 text-xs text-gray-500">
                            Pickup Address & Time
                          </p>
                          <p className="text-sm text-gray-900 font-medium underline decoration-dashed">
                            {tripDetails && tripDetails.jobOriginAddress}
                          </p>
                        </div>
                        <div className="flex-none">
                          <time
                            dateTime="2023-01-23T11:03"
                            className="text-sm font-bold text-primary-500 leading-tight rounded-full px-2 py-1 ring-1 ring-primary-500 bg-green-100"
                          >
                            {tripDetails && (
                              <Moment format="hh:mm A">
                                {tripDetails.scheduleTime}
                              </Moment>
                            )}
                          </time>
                        </div>
                      </li>
                      <li className="relative flex gap-x-4">
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                          {/* <div className="h-2 w-2 rounded-sm bg-red-200 ring-1 ring-red-400"></div> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-3 text-red-400"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12"
                            />
                          </svg>
                        </div>
                        <div className="flex-auto space-y-1">
                          <p className="pt-1 text-xs text-gray-500">
                            Dropoff Address & Time
                          </p>
                          <p className="text-sm text-gray-900 font-medium underline decoration-dashed">
                            {tripDetails && tripDetails.jobDestinationAddress}
                          </p>
                        </div>
                        <div className="flex-none">
                          <time
                            dateTime="2023-01-23T11:03"
                            className="text-sm font-bold text-primary-500 leading-tight rounded-full px-2 py-1 ring-1 ring-primary-500 bg-green-100"
                          >
                            {tripDetails && (
                              <Moment format="hh:mm A">
                                {tripDetails.appointmentTime}
                              </Moment>
                            )}
                          </time>
                        </div>
                      </li>
                    </ul>
                    {!(
                      tripDetails.status === "cancelled" ||
                      tripDetails.status === "finished"
                    ) && (
                      <div className="grid grid-cols-2 gap-4 pt-2">
                        <Button
                          className="w-full"
                          color="primary"
                          href={`sms:;?&body=Hey, ${
                            tripDetails &&
                            tripDetails.priorityClient.displayName.split(" ")[0]
                          } is currently traveling with ${
                            tripDetails && tripDetails.company.displayName
                          } for an appointment. You can track the trip's progress using this link: ${
                            window.location.href
                          }`}
                        >
                          <ShareIcon />
                          Share Trip
                        </Button>
                        <Button
                          color="white"
                          href={`tel:${
                            tripDetails && tripDetails.company.extension
                          }`}
                          className="w-full"
                        >
                          <PhoneIcon />
                          Call
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {!(
            tripDetails.status === "cancelled" ||
            tripDetails.status === "finished"
          ) &&
            tripDetails.driver && (
              <LocationTracker onLocationUpdate={handleLocationUpdate} />
            )}
        </>
      ) : (
        <SkeletonTheme>
          <section className="h-dvh relative">
            <div className="relative p-4 max-w-xl mx-auto space-y-8">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Skeleton height={48} width={48} circle={true} />
                  <Skeleton height={16} width={120} />
                </div>
                <Skeleton height={32} width={32} />
              </div>
              <div>
                <Skeleton height={196} />
              </div>
              <div>
                <Skeleton count={12} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Skeleton height={48} />
                <Skeleton height={48} />
              </div>
            </div>
          </section>
        </SkeletonTheme>
      )}
    </>
  );
}
