import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DriverTracking from "pages/TripTracking";
import MemberReady from "pages/MemberReady";
import TripConfirmation from "pages/TripConfirmation";
import NotFound from "pages/NotFound";
import SelfPortal from "pages/SelfPortal";
import Dashboard from "pages/Dashboard";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/trip-tracking/:tripId"
          element={<DriverTracking />}
        ></Route>
        <Route
          path="/trip-confirmation/:tripId"
          element={<TripConfirmation />}
        ></Route>
        <Route
          path="/trip-member-ready/:tripId"
          element={<MemberReady />}
        ></Route>
        <Route path="/dashboard/:tripId" element={<Dashboard />}></Route>
        {/* <Route path="/self-portal" element={<SelfPortal />}></Route> */}
        <Route path="/expired" element={<NotFound />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
