import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ToastError,
  ToastConfirmedTrip,
  ToastMarkedCancelledTrip,
} from "utils/toasts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MarkerF, PolylineF } from "@react-google-maps/api";
import pickupMarker from "images/pickup.png";
import dropoffMarket from "images/dropoff.png";
import {
  CheckBadgeIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import Moment from "react-moment";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "components/dropdown";
import { Badge } from "components/badge";
import { Button } from "components/button";
import { MapView } from "components/MapView";
import { DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

export default function TripConfirmationPage() {
  const [tripDetails, setTripDetails] = useState(null);
  const [directions, setDirections] = React.useState(null);
  let params = useParams();
  const navigate = useNavigate();
  let id = params.tripId;
  let [map, setMap] = useState();
  let [routePath, setRoutePath] = useState([]);

  useEffect(() => {
    if (!id) return;
    getDataFromApi();
  }, [id]);

  const onLoad = (myMap) => {
    map = myMap;
    setMap(myMap);
    if (tripDetails) setTripsBounds(tripDetails);
  };

  function getDataFromApi() {
    fetch(
      `${process.env.REACT_APP_BASEURL}assigns/tripconfirmation?assignId=${id}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTripDetails(data);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        ToastError(error.message);
      });
  }

  function showMessage(trip) {
    let message = "";

    if (trip.isConfirmedTrip) {
      message = "Your trip is confirmed";
    }

    if (trip.isMarkedCancelledTrip) {
      message = "Your trip is cancelled";
    }

    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            {trip.isConfirmedTrip ? (
              <CheckBadgeIcon className="h-5 w-5 text-green-500" />
            ) : (
              <XCircleIcon className="h-5 w-5 text-red-500" />
            )}
          </div>
          <div className="ml-3">
            <p
              className={`${
                trip.isConfirmedTrip ? "text-green-700" : "text-red-700"
              } text-sm font-medium`}
            >
              {message}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function confirmationTripAlert(confirm) {
    let message = "Do you want to cancel trip?";

    if (confirm) {
      message = "Do you want to confirm trip?";
    }

    Swal.fire({
      title: "Alert",
      icon: "info",
      text: message,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: `No`,
      focusConfirm: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmationTripReq(confirm);
      }
    });
  }

  function confirmationTripReq(confirm) {
    let payload = {
      assignId: id,
    };

    if (confirm) {
      payload.isConfirmedTrip = true;
    } else {
      payload.isMarkedCancelledTrip = true;
    }

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_BASEURL}assigns/tripconfirmation`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          ToastError(data.message);
          return;
        }
        if (payload.isConfirmedTrip == true) {
          ToastConfirmedTrip();
        } else if (payload.isMarkedCancelledTrip == true) {
          ToastMarkedCancelledTrip();
        }
        console.log("data", data);
        getDataFromApi();
      });
  }

  function setTripsBounds(data) {
    const pointA = {
      lat: data.jobOriginLatitude,
      lng: data.jobOriginLongitude,
    };
    const pointB = {
      lat: data.jobDestinationLatitude,
      lng: data.jobDestinationLongitude,
    };

    routePath = [pointA, pointB];
    setRoutePath(routePath);
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(pointA);
    bounds.extend(pointB);
    map.fitBounds(bounds);

      // draw Route 
      const request = {
        origin: pointA,
        destination: pointB,
        travelMode: 'DRIVING'
      };
  
      drawDirection(request);
  }


  const drawDirection = (request) => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        // Set the directions when the request is successful
        console.log(result)
        setDirections(result);
      } else {
        // Handle error
        console.error('Directions request failed due to ' + status);
      }
    });
  };

  return (
    <>
      {tripDetails ? (
        <section className="h-dvh relative">
          <div className="relative p-4 max-w-xl mx-auto space-y-8">
            <div className="rounded-md p-2 ring-1 ring-gray-200 flex justify-between items-center">
              <div>
                {tripDetails.company && tripDetails.company.profileImageURL && (
                  <img
                    src={tripDetails.company.profileImageURL}
                    alt={tripDetails.company.displayName}
                    className="p-1 h-10 max-w-32 rounded-md border object-scale-down"
                  />
                )}
              </div>
              <div className="text-sm font-bold text-gray-900">
                Trip Confirmation
              </div>
              <div>
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem href={`tel:${tripDetails.company.extension}`}>
                      Call Dispatcher
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="bg-white rounded-2xl border aspect-[2/1] shadow-lg overflow-hidden">
              <MapView onLoad={onLoad}>
                {
                  <MarkerF
                    label={{
                      color: "white",
                      fontSize: "11px",
                      fontWeight: "bold",
                      text: "P",
                    }}
                    icon={{
                      url: pickupMarker,
                      scaledSize: {
                        width: 38,
                        height: 38,
                      },
                      labelOrigin: { x: 19, y: 14 },
                    }}
                    position={{
                      lat: tripDetails.jobOriginLatitude,
                      lng: tripDetails.jobOriginLongitude,
                    }}
                  />
                }

                {
                  <MarkerF
                    label={{
                      color: "white",
                      fontSize: "11px",
                      fontWeight: "bold",
                      text: "D",
                    }}
                    icon={{
                      url: dropoffMarket,
                      scaledSize: {
                        width: 38,
                        height: 38,
                      },
                      labelOrigin: { x: 19, y: 14 },
                    }}
                    position={{
                      lat: tripDetails.jobDestinationLatitude,
                      lng: tripDetails.jobDestinationLongitude,
                    }}
                  />
                }

                {/* {routePath && routePath.length && (
                  <PolylineF
                    path={routePath}
                    options={{
                      strokeColor: "#3D5F3D",
                      strokeOpacity: 1,
                      strokeWeight: 4,
                    }}
                  />
                )} */}

                {directions && <DirectionsRenderer directions={directions}
                  options={{
                    suppressMarkers: true, polylineOptions: {
                      strokeColor: "#293562",
                      strokeWeight: 5
                    }
                  }}
                />}
              </MapView>
            </div>
            <div className="bg-[#293562] rounded-2xl overflow-hidden space-y-4">
              <div className="flex flex-col p-6">
                <p className="text-lg text-gray-100">
                  Hi{" "}
                  <span className="font-medium text-yellow-400">
                    {tripDetails.priorityClient
                      ? tripDetails.priorityClient.displayName.split(' ')[0]
                      : "Not Valid Name"}
                  </span>
                  , Please confirm your schedule pickup on{" "}
                  <span className="font-bold text-yellow-400">
                    {tripDetails.scheduleTime ? (
                      <Moment format="ddd D MMM YYYY">
                        {tripDetails.scheduleTime}
                      </Moment>
                    ) : (
                      "Not Valid Date"
                    )}
                  </span>
                </p>
              </div>
              <div className="relative flex items-center after:absolute after:-left-2 after:size-4 after:bg-white  after:rounded-full before:absolute before:-right-2 before:size-4 before:bg-white before:rounded-full px-2">
                <div className="w-full border-t border-dashed border-white/20 mx-2"></div>
              </div>
              <div className="p-6 space-y-8">
                <ul role="list" className="space-y-6">
                  <li className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                      <div className="w-px  border-r border-gray-200 border-dashed"></div>
                    </div>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-[#293562]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 text-green-400"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M13.756 10.164c1.665-.962 1.665-3.366 0-4.328L5.251.919C3.584-.045 1.5 1.158 1.5 3.083v9.834c0 1.925 2.084 3.128 3.751 2.164z"
                        />
                      </svg>
                    </div>
                    <div className="flex-auto space-y-1">
                      <p className="pt-1 text-sm text-gray-100">
                        Pickup Address
                      </p>
                      <p className="text-base text-white font-medium underline decoration-dashed">
                        {tripDetails.jobOriginAddress
                          ? tripDetails.jobOriginAddress
                          : "Not Valid Address"}
                      </p>
                    </div>
                    <div className="flex-none">
                      <time className="pt-1 text-sm font-bold text-gray-900 px-2 py-1 rounded-full bg-gray-100">
                        {tripDetails.scheduleTime ? (
                          <Moment format="hh:mm A">
                            {tripDetails.scheduleTime}
                          </Moment>
                        ) : (
                          "Not Valid Time"
                        )}
                      </time>
                    </div>
                  </li>
                  <li className="relative flex gap-x-4">
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-[#293562]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 text-red-400"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12"
                        />
                      </svg>
                    </div>
                    <div className="flex-auto space-y-1">
                      <p className="pt-1 text-sm text-gray-100">
                        Dropoff Address
                      </p>
                      <p className="text-base text-white font-medium underline decoration-dashed">
                        {tripDetails.jobDestinationAddress
                          ? tripDetails.jobDestinationAddress
                          : "Not Valid Address"}
                      </p>
                    </div>
                    <div className="flex-none">
                      <time className="pt-1 text-sm font-bold text-gray-900 px-2 py-1 rounded-full bg-gray-100">
                        {tripDetails.appointmentTime ? (
                          <Moment format="hh:mm A">
                            {tripDetails.appointmentTime}
                          </Moment>
                        ) : (
                          "Not Valid Time"
                        )}
                      </time>
                    </div>
                  </li>
                </ul>
                {tripDetails.isConfirmedTrip ||
                tripDetails.isMarkedCancelledTrip ? (
                  showMessage(tripDetails)
                ) : (
                  <div className="grid grid-cols-2 gap-4">
                    <Button
                      color="white"
                      className="w-full"
                      onClick={() => confirmationTripAlert(false)}
                    >
                      Cancel Trip
                    </Button>
                    <Button
                      color="green"
                      className="w-full"
                      onClick={() => confirmationTripAlert(true)}
                    >
                      Confirm Trip
                    </Button>
                  </div>
                )}
              </div>
              <div className="relative flex items-center after:absolute after:-left-2 after:size-4 after:bg-white after:rounded-full before:absolute before:-right-2 before:size-4 before:bg-white before:rounded-full px-2">
                <div className="w-full border-t border-dashed border-white/20 mx-2"></div>
              </div>
              <p className="text-base text-red-300 px-6 pb-8 pt-4">
                Please ensure your presence at the designated location so that
                the driver can easily reach you for boarding.
              </p>
            </div>
          </div>
        </section>
      ) : (
        <SkeletonTheme>
          <section className="h-dvh relative">
            <div className="relative p-4 max-w-xl mx-auto space-y-8">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Skeleton height={48} width={48} circle={true} />
                  <Skeleton height={16} width={120} />
                </div>
                <Skeleton height={32} width={32} />
              </div>
              <div>
                <Skeleton height={196} />
              </div>
              <div>
                <Skeleton count={12} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Skeleton height={48} />
                <Skeleton height={48} />
              </div>
            </div>
          </section>
        </SkeletonTheme>
      )}
    </>
  );
}
